import axios from "axios";

export const sendNewPurchaseEmail = (data) => {
  return axios.post("https://api.emailjs.com/api/v1.0/email/send", {
    service_id: "ant-dltx",
    template_id: "purchase",
    user_id: "user_MPbodjNV2SctizJssTgL8",
    template_params: data
  });
};
