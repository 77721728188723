<template>
  <div class="uk-container uk-form-width-large" style="width: 100%">
    <vue-headful
      description="shop"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | shop`"
    />

    <div class="convert" style="width: 100%">
      <h2 class="uk-h2">shop</h2>
      <div class="uk-child-width-expand@s uk-text-center" uk-grid>
        <div v-for="item in items" :key="item.id">
          <div
            class="item uk-flex uk-flex-bottom"
            :style="{ 'background-image': `url(${item.imageUrl})` }"
          >
            <div class="item-content uk-padding">
              <div class="title-wrapper">
                <span class="uk-text-large">{{ item.title }}</span>
              </div>
              <span class="details">size: {{ item.size }}</span>
              <span class="details">price: {{ item.price[paymentToken] }}</span>
              <Select
                id="selectPaymentToken"
                class="uk-width-expand uk-margin-top"
                selectClasses="uk-width-expand"
                :value="paymentToken"
                :options="paymentTokenOptions"
                @change="onSelectPaymentToken"
                :disabled="false"
              />

              <NumberInput
                class="uk-width-expand uk-margin-top"
                id="burnAmount"
                type="number"
                :value="getItemQuantity(item)"
                :min="ethers.BigNumber.from(0)"
                :disabled="false"
                :inputDisabled="true"
                @change="(value) => onIncreaseQuantity(item, value)"
                setValueOnAmount="true"
              />

              <span>Price: {{ getDisplayPrice(item) }}</span>
              <br />
              <span v-if="paymentToken === 'ETH' && balance"
                >Avail: {{ getDisplayBalance() }}</span
              >
              <button
                id="button"
                class="uk-button uk-button-primary uk-width-expand hfi-button"
                type="button"
                @click="() => toggleBuyItemModal(item)"
                :disabled="getItemQuantity(item).isZero() || !account"
              >
                {{ account ? "buy" : "connect wallet" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="buy-item-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
        v-if="!!buyItem"
      >
        <h4 class="">buy {{ buyItem.title }}</h4>
        <a @click.prevent="toggleBuyItemModal" class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>

        <form novalidate autocomplete="off" class="uk-margin-small-top">
          <fieldset class="uk-fieldset uk-width-1-1">
            <label class="uk-form-label uk-width-expand" for="firstName">
              first name *
            </label>
            <input
              id="firstName"
              placeholder="enter first name"
              v-model="firstName"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <label class="uk-form-label uk-width-expand" for="lastName">
              last name *
            </label>
            <input
              id="lastName"
              placeholder="enter last name"
              v-model="lastName"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <label class="uk-form-label uk-width-expand" for="addressLineOne">
              address line 1 *
            </label>
            <input
              id="addressLineOne"
              placeholder="enter address line 1"
              v-model="addressLineOne"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <label class="uk-form-label uk-width-expand" for="addressLineTwo">
              address line 2 *
            </label>
            <input
              id="addressLineTwo"
              placeholder="enter address line 2"
              v-model="addressLineTwo"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <label class="uk-form-label uk-width-expand" for="city">
              city *
            </label>
            <input
              id="city"
              placeholder="enter city"
              v-model="city"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />

            <div class="uk-flex uk-flex-between">
              <div style="width: 48%">
                <label class="uk-form-label uk-width-expand" for="state">
                  state *
                </label>
                <input
                  id="state"
                  placeholder="enter state"
                  v-model="state"
                  class="uk-input uk-margin-bottom"
                  :disabled="buying"
                />
              </div>

              <div style="width: 48%">
                <label class="uk-form-label uk-width-expand" for="postcode">
                  postcode *
                </label>
                <input
                  id="postcode"
                  placeholder="enter postcode"
                  v-model="postcode"
                  class="uk-input uk-margin-bottom"
                  :disabled="buying"
                />
              </div>
            </div>

            <label class="uk-form-label uk-width-expand" for="country">
              country *
            </label>
            <input
              id="country"
              placeholder="enter country"
              v-model="country"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <label
              class="uk-form-label uk-width-expand"
              for="discordUsername"
              v-if="paymentToken === 'FOREX'"
            >
              discord username *
            </label>
            <input
              id="discordUsername"
              placeholder="enter discord username"
              v-model="discordUsername"
              v-if="paymentToken === 'FOREX'"
              class="uk-input uk-margin-bottom"
              :disabled="buying"
            />
            <div class="uk-flex uk-flex-between">
              <div style="width: 48%">
                <label class="uk-form-label" for="totalPrice">
                  total price inc shipping
                </label>
                <input
                  id="totalPrice"
                  class="uk-input"
                  :disabled="buying"
                  :value="getDisplayPrice(buyItem)"
                />
              </div>
              <div class="uk-flex uk-flex-bottom" style="width: 48%">
                <button
                  id="button"
                  class="uk-button uk-button-primary uk-width-expand hfi-button"
                  type="button"
                  @click="onBuy"
                  :disabled="!canBuy"
                >
                  {{ buying ? "" : "buy" }}
                  <vue-loaders-ball-pulse
                    color="currentColor"
                    scale="0.5"
                    v-if="buying"
                  />
                </button>
              </div>
            </div>
          </fieldset>

          <div class="warning-box uk-text-center uk-padding" v-if="!isMainnet">
            <span class="warning"
              >you are connected to the {{ networkDisplayName }} network. only
              purchases made through the ethereum mainnet will be honored.
              please swap networks.
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import UIkit from "uikit";
import Token from "../types/Token";
import NumberInput from "@/components/NumberInput";
import sendTransaction from "../contracts/utils/sendTransaction";
import { store } from "@/store";
import { showNotification, closeAllNotifications } from "@/utils/utils";
import { sendNewPurchaseEmail } from "../utils/emailjs";
import Event from "../types/Event";
import { addEventListener, removeEventListener } from "../utils/event";
import { signer } from "../utils/wallet";
import NetworkType from "../types/Network";

const items = [
  {
    id: "truckers-cap",
    title: "trucker's cap",
    size: "one size",
    imageUrl: "/store/truckers-hat.png",
    price: {
      ETH: "0.017",
      FOREX: "100",
    },
  },
  {
    id: "lambo",
    title: "lambo",
    size: "one size",
    imageUrl: "",
    price: {
      ETH: "1",
      FOREX: "500",
    },
  },
];

const paymentTokenOptions = [Token.ETH, "FOREX"];

export default {
  name: "Shop",
  components: {
    NumberInput,
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
  },
  mounted: async function () {
    const onWalletUpdate = () => {
      this.fetchEthBalance();
    };

    this.walletUpdateEventId = addEventListener(
      Event.WalletUpdate,
      onWalletUpdate.bind(this)
    );
  },
  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },
  data() {
    return {
      ethers,
      items,
      paymentToken: Token.ETH,
      paymentTokenOptions,
      basket: {},
      buyItem: undefined,
      showBuyItemModal: false,
      buying: false,
      bal: undefined,
      firstName: "",
      lastName: "",
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      discordUsername: "",
    };
  },
  computed: {
    canBuy() {
      return (
        store.state.account &&
        this.firstName &&
        this.lastName &&
        this.addressLineOne &&
        this.addressLineTwo &&
        this.city &&
        this.state &&
        this.postcode &&
        this.country &&
        (this.paymentToken !== "FOREX" ||
          (this.paymentToken === "FOREX" && this.discordUsername))
      );
    },
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    networkDisplayName() {
      return store.getters.networkDisplayName;
    },
    isMainnet() {
      return store.state.network === NetworkType.homestead;
    },
  },
  methods: {
    onSelectPaymentToken: function (option) {
      this.paymentToken = option;
    },
    onIncreaseQuantity: function (item, qty) {
      this.basket = {
        ...this.basket,
        [item.id]: qty,
      };
    },
    getItemQuantity: function (item) {
      return this.basket[item.id]
        ? this.basket[item.id]
        : ethers.BigNumber.from(0);
    },
    getPrice: function (item) {
      if (!this.basket[item.id]) {
        return ethers.BigNumber.from(0);
      }
      return ethers.utils
        .parseEther(item.price[this.paymentToken])
        .mul(Number(ethers.utils.formatEther(this.basket[item.id])));
    },
    getDisplayPrice: function (item) {
      return this.basket[item.id] && !this.basket[item.id].isZero()
        ? `${ethers.utils.formatUnits(this.getPrice(item))} ${
            this.paymentToken
          }`
        : "-";
    },
    getDisplayBalance: function () {
      return ethers.utils.formatEther(this.balance);
    },
    toggleBuyItemModal: function (item) {
      this.buyItem = item;
      this.showBuyItemModal = !this.showBuyItemModal;
      const modal = UIkit.modal("#buy-item-modal");
      if (modal) {
        modal[this.showBuyItemModal ? "show" : "hide"]();
      }
    },
    onBuy: async function () {
      this.buying = true;
      let transaction;

      try {
        if (this.paymentToken === "ETH") {
          transaction = await sendTransaction(
            {
              to: process.env.VUE_APP_STORE_ACCOUNT,
              value: this.getPrice(this.buyItem),
            },
            "waiting for transaction approval...",
            undefined,
            async (_t, ethScanMessage) =>
              `transaction successful. ${ethScanMessage}`
          );
        }

        showNotification("success", `completing purchase...`, undefined, 0);

        const qty = ethers.utils.formatEther(this.basket[this.buyItem.id]);

        await sendNewPurchaseEmail({
          isMainnet: this.isMainnet.toString(),
          qty,
          product: this.buyItem.title,
          totalCost: this.getDisplayPrice(this.buyItem),
          transaction: transaction ? transaction.transactionHash : "-",
          firstName: this.firstName,
          lastName: this.lastName,
          addressLineOne: this.addressLineOne,
          addressLineTwo: this.addressLineTwo,
          city: this.city,
          state: this.state,
          postcode: this.postcode,
          country: this.country,
          discordUsername: this.discordUsername || "-",
        });

        showNotification(
          "success",
          `purchase of ${qty} x ${this.buyItem.title} successful`,
          undefined,
          0
        );

        this.toggleBuyItemModal();
      } catch (error) {
        console.error(error);
        closeAllNotifications();
        showNotification("danger", `purchase transaction failed.`);
      }

      this.buying = false;
    },
    fetchEthBalance: async function () {
      if (!store.state.account) {
        this.balance = undefined;
        return;
      }

      this.balance = await signer.getBalance();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;

.item {
  background-repeat: no-repeat;
  background-size: 90% 90%;
  background-position: center;
  border: 2px solid handle.$green;

  .title-wrapper {
    padding-bottom: 15px;
    border-bottom: 2px solid handle.$green;
    margin-bottom: 10px;
  }

  .details {
    font-size: 20px;
    display: block;
  }
}
</style>
